import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const EditSubCategoryModal = ({ show, onHide, onSave, category, viewOnly }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (show) {
			form.setFieldsValue(category); // Populate form with category data
		}
	}, [show, category, form]);

	const handleSave = async () => {
		try {
			setLoading(true);
			const formData = await form.validateFields();
			onSave(formData);  // Save only in edit mode
		} catch (error) {
			console.error('Error saving data:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			title={viewOnly ? "View Member" : "Edit Member"}
			visible={show}
			onCancel={onHide}
			footer={!viewOnly && [  // Footer only shows in edit mode
				<Button key="cancel" onClick={onHide}>
					Cancel
				</Button>,
				<Button key="save" type="primary" loading={loading} onClick={handleSave}>
					Save
				</Button>,
			]}
		>
			<Form form={form} layout="vertical">
				<Form.Item name="firstname" label="Name">
					<Input disabled={viewOnly} />  {/* Disabled when in view-only mode */}
				</Form.Item>
				<Form.Item name="mobile" label="Mobile">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="email" label="Email">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="dob" label="DOB">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="address" label="Address">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="mparea" label="MP Area">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="mlaarea" label="MLA Area">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="aadharcardnumber" label="Aadhar Card Number">
					<Input disabled={viewOnly} />
				</Form.Item>
				<Form.Item name="gender" label="Gender">
					<Select disabled={viewOnly}>
						<Option value="Male">Male</Option>
						<Option value="Female">Female</Option>
						<Option value="Others">Others</Option>
					</Select>
				</Form.Item>
				<Form.Item name="role" label="Role">
					<Select disabled={viewOnly}>
						<Option value="USER">User</Option>
						<Option value="ADMIN">Admin</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditSubCategoryModal;
